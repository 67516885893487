<!--
 * @Author: mulingyuer
 * @Date: 2023-02-07 15:27:18
 * @LastEditTime: 2023-02-11 17:45:06
 * @LastEditors: mulingyuer
 * @Description: 复盘表格弹窗
 * @FilePath: \admin_site\src\view\content\match\components\info_s1\ReListingDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    :close-on-click-modal="false"
    v-loading="loading"
    width="fit-content"
    class="relisting-dialog"
    @open="onDialogOpen"
    @close="onDialogClose"
  >
    <div class="relisting-dialog-content">
      <div class="relisting-dialog-head">
        <div class="relisting-dialog-session">
          场次：
          {{
            rowData.session_name != ""
              ? rowData.session_name
              : `第${rowData.group}组第${rowData.index}场`
          }}
        </div>
        <el-upload
          class="relisting-dialog-upload"
          action=""
          :multiple="false"
          :show-file-list="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :auto-upload="false"
          :on-change="onUploadChange"
        >
          <el-button size="small" type="primary">导入复盘数据Excel</el-button>
        </el-upload>
      </div>
      <div class="relisting-dialog-body">
        <el-form
          :model="formData"
          ref="ruleForm"
          :rules="rules"
          label-width="0"
        >
          <el-table :data="formData.list" border max-height="350">
            <el-table-column
              v-for="th in thead"
              :key="th.prop"
              :prop="th.prop"
              :label="th.label"
              :width="th.width"
            >
              <template slot-scope="{ row, $index }">
                <el-form-item
                  label-width="0"
                  :prop="`list[${$index}].${th.prop}`"
                  :rules="rules[th.prop] || []"
                  class="relisting-dialog-form-item"
                >
                  <el-input
                    v-model="row[th.prop]"
                    :placeholder="th.label"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <div class="relisting-dialog-footer">
            <el-form-item>
              <el-button @click="onCancel">取消</el-button>
              <el-button
                type="primary"
                :disabled="formData.list.length <= 0"
                :loading="saveLoading"
                @click="onConfirm('ruleForm')"
                >确认</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { readExcel } from "@/utils/excelTool";
import { saveReplay, getReplay } from "@/http/modules/match/match";
//表格文件头信息常量
const EXCEL_HEAD = [
  { label: "比赛天数", prop: "match_day_number" },
  { label: "比赛状态", prop: "match_day_status" },
  { label: "比赛行为", prop: "match_action" },
  { label: "行为发起方", prop: "action_initiator" },
  { label: "行为接受方", prop: "action_recipient" },
];
export default {
  model: {
    prop: "visible",
    event: "update:visible",
  },
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      required: true,
    },
    /** 弹窗类型：1新增复盘；2编辑复盘 */
    type: {
      type: Number,
      default: 1,
    },
    /** 表格row行数据 */
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    //自定义校验桌号
    function checkTableNumber(rule, value, callback) {
      //允许为空
      if (typeof value === "string" && value.trim() === "") {
        return callback();
      }
      //有值
      const list = String(value).split(",");
      const findIndex = list.findIndex((day) => {
        day = Number(day);
        return day > 12 || day <= 0 || !Number.isInteger(day);
      });
      if (findIndex !== -1) {
        return callback(new Error("桌号不能超出1-12范围的整数"));
      }
      callback();
    }
    return {
      loading: false,
      //表格头部
      thead: [
        { label: "比赛天数", prop: "match_day_number", width: 100 },
        { label: "比赛状态", prop: "match_day_status", width: 120 },
        { label: "比赛行为", prop: "match_action", width: 180 },
        { label: "行为发起方", prop: "action_initiator", width: 180 },
        { label: "行为接受方", prop: "action_recipient", width: 180 },
      ],
      //表单数据
      formData: {
        list: [], //表格数据
      },
      //表单校验规则
      rules: {
        action_initiator: [{ trigger: "blur", validator: checkTableNumber }],
        action_recipient: [{ trigger: "blur", validator: checkTableNumber }],
      },
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    /** 弹窗标题 */
    title({ type }) {
      switch (type) {
        case 1:
          return "新增复盘";
        case 2:
          return "编辑复盘";
        default:
          return "未知类型";
      }
    },
  },
  methods: {
    /** 自定义文件上传事件 */
    onUploadChange(file) {
      const { raw: fileRaw } = file;

      //校验文件
      if (!fileRaw) {
        this.$message({
          type: "warning",
          message: "请上传附件！",
        });
      }
      if (
        fileRaw.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        fileRaw.type !== "application/vnd.ms-excel"
      ) {
        this.$message({
          type: "warning",
          message: "附件格式错误，请删除后重新上传！",
        });
      }

      //读取文件
      readExcel(fileRaw)
        .then((data) => {
          const sheetNames = Object.keys(data);

          //取第一个sheet子表
          const sheetData = data[sheetNames[0]];
          const list = this.formattingExcelData(sheetData);
          this.formData.list = list;
          //校验
          this.$nextTick(() => {
            //校验比赛天数是否合法
            this.matchDayNumberValidator();
            //手动校验表单
            this.$refs["ruleForm"].validate(() => {});
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
          console.log("读取excel文件发生错误", error);
        });
    },
    /** 格式化上传的excel文件数据
     * data: excel文件数据 Array
     * 数组第一个数据是注释表头，需要去除
     * 之后每个item中将需要的数据提取处理，返回新的数组
     */
    formattingExcelData(data) {
      const newData = data.slice(1);
      const list = newData.map((item) => {
        const obj = {};
        EXCEL_HEAD.forEach(({ label, prop }) => {
          obj[prop] = item[label] || ""; //当表格里没有填时不会有该字段，需要设置默认值
        });
        return obj;
      });
      return list;
    },
    /** 取消按钮 */
    onCancel() {
      if (this.saveLoading) return;
      this.show = false;
    },
    /** 确认按钮 */
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        const validDay = this.matchDayNumberValidator();
        if (!validDay) return;
        //保存
        this.saveLoading = true;
        this.onSaveReplay()
          .then((res) => {
            this.$message.success("保存成功");
            this.show = false;
            this.$emit("confirm", res);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      });
    },
    /** 重置表单 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 比赛天数顺序校验 */
    matchDayNumberValidator() {
      const { list } = this.formData;
      //提取出所有天数并去重
      const matchDayNumberList = list.map((item) => item.match_day_number);
      let lastDay = null;
      const dayList = [];
      matchDayNumberList.forEach((day) => {
        if (!lastDay || lastDay !== day) {
          lastDay = day;
          dayList.push(Number(day));
          return;
        }
      });
      //判断天数是否连续
      let firstDay = 1; //比赛从第一天开始
      const findData = dayList.find((day) => {
        if (firstDay !== day) {
          firstDay++;
          if (firstDay !== day) return day;
        }
        return false;
      });
      if (typeof findData === "number") {
        this.$message({
          type: "warning",
          message: `比赛天数必须是顺序的，请修改excel表格中的数据`,
        });
        return false;
      }
      return true;
    },
    /** 弹窗关闭事件 */
    onDialogClose() {
      this.resetForm("ruleForm");
    },
    /** 保存复盘结果 */
    onSaveReplay() {
      return new Promise((resolve, reject) => {
        const postData = {
          match_id: this.rowData.match_id,
          session_id: this.rowData._id,
          match_replays: this.formData.list,
        };
        saveReplay(postData)
          .then((res) => {
            const {
              data: { status },
            } = res;
            if (status === 0) {
              return reject(new Error(`保存复盘结果失败`));
            }
            return resolve(res);
          })
          .catch((error) => {
            console.log("保存复盘结果失败", error);
            return reject(error);
          });
      });
    },
    /** 获取复盘结果 */
    onGetReplay() {
      return new Promise((resolve, reject) => {
        const postData = {
          match_id: this.rowData.match_id,
          session_id: this.rowData._id,
        };
        getReplay(postData)
          .then((res) => {
            const { data } = res;
            if (!Array.isArray(data)) {
              return reject(new Error(`获取复盘结果失败`));
            }
            return resolve(data);
          })
          .catch((error) => {
            console.log("获取复盘结果失败", error);
            return reject(error);
          });
      });
    },
    /** 弹窗打开事件 */
    onDialogOpen() {
      this.formData.list = [];
      if (this.type !== 2) return;
      this.loading = true;
      this.onGetReplay()
        .then((data) => {
          this.formData.list = data;
          //校验
          this.$nextTick(() => {
            //校验比赛天数是否合法
            this.matchDayNumberValidator();
            //手动校验表单
            this.$refs["ruleForm"].validate(() => {});
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.relisting-dialog-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.relisting-dialog-form-item {
  margin-top: 5px;
  margin-bottom: 20px;
}
.relisting-dialog-footer {
  margin-top: 20px;
  text-align: right;
}
</style>
